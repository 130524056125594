<script>
import privacy from './privacy'
export default {
  extends: privacy,
  components: {},
  props: {},
  data () {
    return {
      format: 'imprint'
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>
